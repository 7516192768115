import React, {useState, useRef, useEffect} from 'react';
import PageHeader from "../_pageHeader";
import {useForm} from "react-hook-form";
import * as Helper from "../form/form_inscription_helper";
import {Form} from "react-bootstrap";
import CustomComboBox from "../form/CustomComboBox";
import CustomFieldText from "../form/CustomFieldText";
import * as Pattern from "../form/form_pattern_helper";
import BirthDayField from "../form/BirthDayField";
import ReCAPTCHA from "react-google-recaptcha";
import Contact from "./contact";
import {Link} from "react-router-dom";
import AutoScroll from "../_autoScroll";

const ParticipationForm = (props) => {

    const {register, handleSubmit, errors} = useForm({validateCriteriaMode: "all"});
    const recaptchaRef = useRef(null);
    const submitRef = useRef(null);

    //form validation
    const onSubmit = data => {

        submitRef.current.setAttribute("disabled", "disabled");

        //controle recaptcha
        document.getElementById("recaptcha_error").innerHTML = " ";
        const recaptcha = recaptchaRef.current.getValue();

        //check recaptcha
        if (recaptcha.length === 0) {
            document.getElementById("recaptcha_error").innerHTML = Helper.EmptyErrorMessages.recaptcha;
            submitRef.current.removeAttribute("disabled");
            return false;
        }

        //RAZ global error
        document.getElementById("global_error").innerHTML = "";

        let formData = new FormData();
        formData.append('form', JSON.stringify(data));
        formData.append("recaptcha", recaptcha);

        fetch(process.env.REACT_APP_API_URL + '/submit_form.php',
            {
                method: 'POST',
                body: formData
            })
            .then(response => response.json())
            .then(json => {

                if (json.success === true) {
                    window.scrollTo(0, 0);
                    props.setStep(4);
                } else {
                    //setFormError(json.error);

                    if (json.error.indexOf("#") >= 0) {
                        //seul l'erreur max_participation est possible
                        document.getElementById("global_error").innerHTML = "L'opération est limitée à 5 participations par adresse e-mail !";
                    } else {
                        alert(json.error);
                    }

                    submitRef.current.removeAttribute("disabled");
                    window.grecaptcha.reset();
                }

            });

    };

    return (
        <Form id={"form_participe"} onSubmit={handleSubmit(onSubmit)} noValidate={true}>

            <input type={"hidden"} name={"team"} value={props.team} ref={register()}/>

            <CustomComboBox name="civilite" id="civilite" label={"Civilité*"} placeholder={"SÉLECTIONNER"}
                            options={[
                                [
                                    "Madame",
                                    "Madame"
                                ],
                                [
                                    "Monsieur",
                                    "Monsieur"
                                ],
                                [
                                    "Je préfère ne pas répondre",
                                    "na"
                                ]
                            ]}
                            register={register({required: {value: true, message: Helper.EmptyErrorMessages.civilite}})}
                            error={errors.civilite && errors.civilite.message}/>

            <CustomFieldText name="prenom" label="Prénom*" register={register({
                required: {value: true, message: Helper.EmptyErrorMessages.prenom},
                pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.prenom},
                minLength: {value: 2, message: Helper.InvalidErrorMessages.prenom}
            })} error={errors.prenom && errors.prenom.message}/>

            <CustomFieldText name="nom" label="Nom*" register={register({
                required: {value: true, message: Helper.EmptyErrorMessages.nom},
                pattern: {value: Pattern.alphaPattern, message: Helper.InvalidErrorMessages.nom},
                minLength: {value: 2, message: Helper.InvalidErrorMessages.nom}
            })} error={errors.nom && errors.nom.message}/>

            <BirthDayField
                label={"Date de naissance<span>*</span>"}
                register={
                    register({
                        required: true,
                        validate: Helper.checkBirthDate
                    })}
                error={[
                    (errors.jour_naissance && errors.jour_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance) ||
                    (errors.mois_naissance && errors.mois_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance) ||
                    (errors.annee_naissance && errors.annee_naissance.type === "required" && Helper.EmptyErrorMessages.date_naissance),
                    (errors.jour_naissance && errors.jour_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age) ||
                    (errors.mois_naissance && errors.mois_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age) ||
                    (errors.annee_naissance && errors.annee_naissance.type === "validate" && Helper.InvalidErrorMessages.date_naissance_age)

                ]}/>

            <CustomFieldText name="mail" label="Adresse e-mail*"
                             register={
                                 register({
                                     required: true,
                                     pattern: Pattern.emailPattern,
                                     validate: {
                                         domain: (value) => Helper.checkMailDomain(value)
                                         /*confirm : checkMailConfirm*/
                                     }
                                 })}
                             error={[
                                 errors.mail && errors.mail.type === "required" && Helper.EmptyErrorMessages.mail,
                                 errors.mail && errors.mail.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                 errors.mail && errors.mail.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                 errors.mail && errors.mail.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                             ]}/>

            <CustomFieldText name="mail_confirm"
                             label="Confirmation de <br class='d-lg-none'/>l'adresse e-mail*"
                             register={
                                 register({
                                     required: true,
                                     pattern: Pattern.emailPattern,
                                     validate: {
                                         domain: (value) => Helper.checkMailDomain(value),
                                         confirm: Helper.checkMailConfirm
                                     }
                                 })}
                             error={[
                                 errors.mail_confirm && errors.mail_confirm.type === "required" && Helper.EmptyErrorMessages.mail,
                                 errors.mail_confirm && errors.mail_confirm.type === "pattern" && Helper.InvalidErrorMessages.mail,
                                 errors.mail_confirm && errors.mail_confirm.type === "domain" && Helper.InvalidErrorMessages.mail_domain,
                                 errors.mail_confirm && errors.mail_confirm.type === "confirm" && Helper.InvalidErrorMessages.mail_dif
                             ]}/>

            <CustomFieldText name="cp"
                             label="Code postal*"
                             register={register({
                                 required: {value: true, message: Helper.EmptyErrorMessages.cp},
                                 pattern: {
                                     value: Pattern.numberAndAlphaPattern_nospace,
                                     message: Helper.InvalidErrorMessages.cp
                                 },
                                 minLength: {value: 5, message: Helper.InvalidErrorMessages.cp},
                                 maxLength: {value: 5, message: Helper.InvalidErrorMessages.cp},
                                 validate : (value) => {
                                        const cp_base = value.substr(0, 2)
                                        return cp_base != "97" && cp_base != "98"
                                    }
                             })}
                             error={[
                                 errors.cp && errors.cp.type !== "validate" && errors.cp.message,
                                 errors.cp && errors.cp.type === "validate"  && Helper.InvalidErrorMessages.cp_domtom
                             ]}/>

            <div id={"optin_reglement_wrapper"} className="checkbox_wrapper form_row_wrapper">
                <input type="checkbox" name="optin_reglement" id="optin_reglement" value="1" ref={register({required: true})}/>
                <label htmlFor="optin_reglement">
                    Je reconnais avoir pris connaissance du <a href={"/fr/fr/xp/choisistonmatch/file/Règlement.pdf"} target={"_blank"}>Règlement du Jeu</a> et l’accepte.<span>*</span>
                </label>
                <div className="error text-center">{errors.optin_reglement && Helper.EmptyErrorMessages.optin_reglement}</div>
            </div>

            <div id={"optin_tictac_wrapper"} className="checkbox_wrapper form_row_wrapper">
                <input type="checkbox" name="optin_tictac" id="optin_tictac" value="1" ref={register()}/>

                <label htmlFor="optin_tictac">
                    Oui, je souhaite recevoir des bons plans exclusifs et promos de la part de Tic Tac® et des autres marques du groupe Ferrero (Nutella®, Kinder®, Délichoc®, Ferrero Rocher...) par e-mail.<sup>(1)</sup>
                </label>
            </div>

            <div id={"optin_tlc_wrapper"} className="checkbox_wrapper form_row_wrapper">
                <input type="checkbox" name="optin_tlc" id="optin_tlc" value="1" ref={register()}/>

                <label htmlFor="optin_tlc">
                    Oui, j’accepte d’être contacté par TLC Marketing dans le cadre de la présente offre pour recueillir mon avis sur la promotion commerciale ainsi qu’à des fins d’enquête de satisfaction, d’élaboration de statistiques, d’organisation de jeux concours ou d’envoi d’informations par e-mail.<sup>(2)</sup>
                </label>
            </div>

            <div id={"rgpd_text_wrapper"}>

                <p>
                    (1) Conformément à la loi Informatique et libertés du 6 janvier 1978 dans sa dernière version en
                    vigueur ainsi que le Règlement UE 2016/679 du 27 avril 2016 publié au Journal Officiel de l’Union
                    Européenne le 4 mai 2016 relatif à la protection des personnes physiques à l'égard du traitement des
                    données à caractère personnel et à la libre circulation de ces données, les informations recueillies
                    sont destinées à TLC Marketing en sa qualité de sous-traitant.
                </p>
                <p>
                    Les données à caractère personnel ainsi collectées font l’objet d’un traitement dont le responsable
                    est FERRERO France Commerciale, 18 rue Jacques Monod, 76130 Mont Saint-Aignan.
                </p>
                <p>
                    Ces données sont collectées afin de vous faire parvenir des informations sur nos produits et/ou, le
                    cas échéant, sur les offres de nos partenaires. Elles seront conservées pour 24 mois, renouvelables
                    à chaque consentement de votre part. Le renouvellement du dit consentement étant défini comme
                    l’interaction (le clic) avec l’une de nos newsletters. Vos coordonnées seront automatiquement
                    effacées de notre base de données si vous n’interagissez avec aucune de nos newsletters pendant une
                    durée de 24 mois ou au terme de 20 newsletters reçues sans interaction de votre part.
                </p>
                <p>
                    Conformément à la réglementation applicable en matière de données à caractère personnel, vous
                    disposez d’un droit d’accès, de rectification, d’opposition, de limitation du traitement,
                    d’effacement et de portabilité de vos données que vous pouvez exercer par e-mail à l’adresse
                    suivante <a href={"mailto:privacy.fr@ferrero.com"}>privacy.fr@ferrero.com</a> en précisant vos nom et prénom
                    .
                </p>
                <p>
                    En cas de difficulté en lien avec la gestion de vos données personnelles, vous pouvez adresser une
                    réclamation auprès du délégué à la protection des données personnelles <a href={"mailto:privacy.fr@ferrero.com"}>privacy.fr@ferrero.com</a> ou
                    auprès de la CNIL ou de toute autre autorité compétente.
                </p>
                <p>
                    (2) TLC Marketing prend les mesures conformes à l’état de l’art afin d’assurer la sécurité et la
                    confidentialité des données, conformément aux dispositions légales précitées. Ces informations
                    seront conservées jusqu’au 31/12/2027 si vous avez coché la case opt-in TLC Marketing, sinon vos
                    données seront supprimées à partir du 31/03/2025. Vous bénéficiez d’un droit d’accès, de
                    rectification, d’opposition, de limitation, d’effacement, de portabilité et d’information concernant
                    vos données personnelles. Afin d’exercer ces droits, nous vous remercions de bien vouloir adresser
                    votre demande auprès de TLC Marketing par e-mail à <a href={"mailto:francedata@tlcrewards.com?subject=Mes%20données%20personnelles"}>francedata@tlcrewards.com</a> ou par courrier à
                    l’adresse suivante : TLC Marketing – Mes données personnelles, 92 avenue de Wagram, 75017 Paris.
                </p>

            </div>

            <div id={"participe_mention_obligatoire"}>
                *Champs obligatoires. Toute demande incomplète ne pourra <br className={"d-lg-none"}/>être validée et traitée.
            </div>

            {/* recaptcha */}
            <div className="text-center" id={"recaptcha_wrapper"}>
                <ReCAPTCHA className="d-inline-block" sitekey="6LevKCQhAAAAAErcCcwZpbzQM4R_JcNDUrml4-2b"
                           ref={recaptchaRef}/>
                <div className="error text-center" id="recaptcha_error">&nbsp;</div>
            </div>

            {/* server error */}
            <div className="text-center">
                <div className={"error text-center mb-3"} id={"global_error"}>
                    {/*error*/}
                </div>
                <button className={"cta"} id={"submit_cta"} ref={submitRef}>je participe</button>
            </div>


        </Form>
    );
};

const Game = (props) => {

    const questions = [
        {
            id: 1,
            question: "Votre <span>danse</span> préférée",
            answer:
                [
                    ["<span>Une salsa</span> passionnelle&nbsp;!", "passion"],
                    ["<span>Un rock</span> endiablé&nbsp;!", "dynamique"],
                    ["<span>Une valse</span> fraîche et légère&nbsp;!", "fraicheur"]
                ]
        },
        {
            id: 2,
            question: "Votre <span>saison</span> préférée  ",
            answer:
                [
                    ["<span>Le printemps</span> pour le retour de ses jolies fleurs&nbsp;!", "passion"],
                    ["<span>L'été</span> pour les activités en plein air&nbsp;!", "dynamique"],
                    ["<span>L'hiver</span> pour se rafraîchir les idées&nbsp;!", "fraicheur"]
                ]
        },
        {
            id: 3,
            question: "Votre <span>plat</span> préféré ",
            answer:
                [
                    ["<span>Des spaghettis</span> entrelacés&nbsp;!", "passion"],
                    ["<span>Des fajitas</span> épicées&nbsp;!", "dynamique"],
                    ["<span>Des sushis</span> bien frais&nbsp;!", "fraicheur"]
                ]
        },
        {
            id: 4,
            question: "Votre <span>activité du week-end</span> préférée ",
            answer:
                [
                    ["<span>zZzZzZz</span> sur le canapé&nbsp;!", "passion"],
                    ["<span>Préparer</span> votre corps d'athlète&nbsp;!", "dynamique"],
                    ["<span>Prendre l’air</span> en forêt&nbsp;!", "fraicheur"]
                ]
        },
        {
            id: 5,
            question: "Votre <span>passe-temps</span> préféré des vacances",
            answer:
                [
                    ["<span>Faire la crêpe</span> au soleil&nbsp;!", "passion"],
                    ["<span>Une randonnée</span> pour se remettre en jambe&nbsp;!", "dynamique"],
                    ["<span>Un plouf</span> dans la piscine&nbsp;!", "fraicheur"]
                ]
        },
        {
            id: 6,
            question: "Votre <span>destination</span> préférée pour les vacances",
            answer:
                [
                    ["<span>Paris</span> et son romantisme&nbsp;!", "passion"],
                    ["<span>New York</span> et ses gratte-ciels&nbsp;!", "dynamique"],
                    ["<span>Le Pôle Nord</span> et sa neige&nbsp;!", "fraicheur"]
                ]
        },
        {
            id: 7,
            question: "Votre <span>sport</span> préféré",
            answer:
                [
                    ["<span>De la détente</span> avant tout&nbsp;!", "passion"],
                    ["<span>Des sensations fortes</span> ou rien&nbsp;!", "dynamique"],
                    ["<span>Deux skis</span> et c'est parti&nbsp;!", "fraicheur"]
                ]
        },
        {
            id: 8,
            question: "Votre <span>soirée</span> idéale",
            answer:
                [
                    ["<span>Regarder un bon film</span> sous un plaid&nbsp;!", "passion"],
                    ["<span>Danser</span> jusqu’au bout de la nuit&nbsp;!", "dynamique"],
                    ["<span>Boire des smoothies</span> rafraîchissants entre amis&nbsp;!", "fraicheur"]
                ]
        },
        {
            id: 9,
            question: "Votre <span>logement</span> pour les vacances",
            answer:
                [
                    ["<span>Un hôtel 4 étoiles</span> bien cocooning&nbsp;!", "passion"],
                    ["<span>Un van</span> avec vue sur le lac&nbsp;!", "dynamique"],
                    ["<span>Un chalet</span> à la montagne&nbsp;!", "fraicheur"]
                ]
        },
        {
            id: 10,
            question: "Votre <span>tenue</span> préférée",
            answer:
                [
                    ["Une tenue <span>élégante&nbsp;!</span>", "passion"],
                    ["<span>Jean baskets&nbsp;!</span>", "dynamique"],
                    ["<span>Une doudoune</span> bien chaude&nbsp;!", "fraicheur"]
                ]
        },
        {
            id: 11,
            question: "Votre <span>animal</span> préféré",
            answer:
                [
                    ["Des oiseaux <span>inséparables&nbsp;!</span>", "passion"],
                    ["<span>Un félin</span> curieux et rapide&nbsp;!", "dynamique"],
                    ["<span>Un pingouin</span> trop frais&nbsp;!", "fraicheur"]
                ]
        },
    ];

    const [quizzQuestions, setQuizzQuestions] = useState([]);
    const [quizzAnswers, setQuizzAnswers] = useState([]);
    const [questionCount, setQuestionCount] = useState(1);

    const questionsTemp = useRef();

    const selectQuestions = () => {

        let questionArr = [];

        for(let i=0; i<3; i++){
            const random = Math.floor(Math.random() * questionsTemp.current.length)
            questionArr.push(questionsTemp.current[random])
            questionsTemp.current.splice(random, 1)
        }

        setQuizzQuestions(questionArr)
    }
    const selectAnswer = (team) => {

        setQuizzAnswers( (arr) => [...arr,team]);
        setQuestionCount(questionCount + 1)
    }
    const startGame = () => {

        //reset question array
        questionsTemp.current = questions;

        //on supprime aléatoirement la question 3 ou 4 car elle ne doivent pas sortir dans le même quizz
        const random = Math.floor(Math.random() * 2)

        if (random === 0) {
            questionsTemp.current.splice(3, 1);
        } else {
            questionsTemp.current.splice(4, 1);
        }

        //reset everything
        //setCurrentQuestion("");
        setQuestionCount(1);

        //select 3 question randomly
        selectQuestions()
    }

    const handleBackCta = () => {
        if(questionCount > 1){
            //remove answer
            setQuizzAnswers(arr => {
                return arr.filter((_, i) => i !== (questionCount-2))
            })

            setQuestionCount(questionCount-1);
        }
        else
        {
            props.setStep(1);
        }
    }

    useEffect(startGame, []);

    useEffect(() => {
        if(quizzAnswers.length === 3){
            let answer = {
                "fraicheur": 0,
                "dynamique": 0,
                "passion": 0
            }

            quizzAnswers.forEach((element) => answer[element]++)
            const userTeam = Object.keys(answer).reduce((a, b) => answer[a] > answer[b] ? a : b);
            props.handleQuizzEnd(userTeam);
        }
    },[quizzAnswers])

    return (
        <>
            <div className={"quizz_wrapper"}>
                <a className={"cta_back"} onClick={handleBackCta}>Retour</a>
                <div className={"question_count"}>
                    {questionCount}/3
                </div>
                {quizzQuestions[questionCount-1] !== undefined &&
                    <div className={"question_wrapper"} id={`question_wrapper${quizzQuestions[questionCount-1].id}`}>
                        <div className={"title"} dangerouslySetInnerHTML={{__html: quizzQuestions[questionCount-1].question}}/>
                        <div className={"answer_wrapper"}>
                            {
                                quizzQuestions[questionCount-1].answer.map((answer, i) => {
                                    return (<div key={`answer${i}`} dangerouslySetInnerHTML={{__html: answer[0]}}
                                                 onClick={() => selectAnswer(answer[1])}/>);
                                })
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    )

}

const Quizz = () => {

    const [step, setStep] = useState(1);
    const [team, setTeam] = useState("");

    const handleQuizzEnd = (quizz_result) => {
        setTeam(quizz_result);
        setStep(3);
    }

    let date_tirage = "";
    const today = new Date();

    if(today <= new Date("2023-10-23"))
        date_tirage = "23 octobre 2023"
    else if(today <= new Date("2023-11-6"))
        date_tirage = "6 novembre 2023"
    else if(today <= new Date("2023-11-6"))
        date_tirage = "20 novembre 2023"
    else
        date_tirage = "4 décembre 2023"


    return (
        <>
            <PageHeader/>
            <AutoScroll/>

            {step === 1 &&
                <div id={"quizzStrate1"} className={"strate"}>
                    <div className={"text-center"}>
                        <h1 className={"picture"}>Répondez au quizz pour découvrir votre match tic tac</h1>
                        <div className={"visuel"}/>
                        <button className={"cta big"} onClick={() => setStep(2)}>Lancer le quizz</button>
                    </div>
                </div>
            }

            {step === 2 &&
                <div id={"quizzStrate2"} className={"strate"}>
                    <Game handleQuizzEnd={handleQuizzEnd} setStep={setStep}/>
                </div>
            }

            {step === 3 &&
                <div id={"quizzStrate3"} className={"strate"}>
                    <h1 className={"picture"}>Vous matchez avec</h1>
                    <div className={`team ${team}`}>

                        {team === "fraicheur" &&
                            <>
                                <b>Vous êtes vraiment trop frais !</b><br/>
                                Vous n’avez pas froid aux yeux et n’hésitez <br className={"d-lg-none"}/>pas à embarquer <br className={"d-none d-lg-block"}/>votre entourage dans des aventures rafraîchissantes !
                            </>
                        }

                        {team === "dynamique" &&
                            <>
                                <b>Vous aimez bouger !</b><br/>
                                Toujours partant(e) pour partager un maximum de fun et d’activités ! <br className={"d-none d-lg-block"}/>
                                Avec vous, la vie est une explosion de saveurs tous les jours !
                            </>
                        }

                        {team === "passion" &&
                            <>
                                <b>Vous chérissez les moments à 2 !</b><br/>
                                Romantique au grand cœur, vous êtes généreux(se) et débordant(e) de tendresse. <br className={"d-none d-lg-block"}/>
                                Passion et douceur n’ont aucun secret pour vous !
                            </>
                        }

                    </div>

                    <h2 className={"picture"}></h2>
                    <p>En complétant le formulaire ci-dessous !</p>

                    <ParticipationForm team={team} setStep={setStep}/>
                </div>
            }

            {step === 4 &&
                <>
                    <div id={"quizzStrate4"} className={"strate"}>

                        <h1 className={"picture"}>Merci</h1>
                        <p>Votre inscription au tirage au sort du {date_tirage} pour tenter de gagner l’une des dotations de votre team a bien été enregistrée.</p>
                        <p><b>Si vous êtes tiré(e) au sort,</b> vous recevrez <b>sous 7 jours ouvrés suivant la date du tirage au sort</b> un e-mail avec la dotation que vous avez gagnée et les modalités pour en bénéficier.</p>
                        <p>N’oubliez pas de regarder dans vos courriers indésirables ou spams !</p>

                        <div className={"text-center"}>
                            <Link to={"/"}>Retour à l'accueil</Link>
                        </div>
                    </div>
                    <Contact header={false} mention={false}/>
                </>
            }

        </>
    );
};


export default Quizz;



