import React from 'react';
import {NavLink} from "react-router-dom";
import {Container} from 'react-bootstrap';

import {HashLink as Link} from 'react-router-hash-link';

class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      menu_open: false
    };

    this.toggleMenuState = this.toggleMenuState.bind(this);
  }

  toggleMenuState() {

    if (!this.state.menu_open) {
      this.setState({menu_open: true});
      document.body.classList.add("no-scroll");
    } else {
      this.setState({menu_open: false});
      document.body.classList.remove("no-scroll");
    }
  }

  detectIE() {

    var ua = window.navigator.userAgent;

    // IE 10 or older => return version number
    var msie = ua.indexOf('MSIE');
    if (msie > 0) {
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    // IE 11 => return version number
    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    // Edge (IE 12+) => return version number
    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

  render() {

    let IE_warning;

    if (this.detectIE() !== false)
      IE_warning =
        <div id="IE_warning">Ce site est optimisé pour fonctionner sur les navigateurs les plus récents (chrome,
          firefox, microsoft edge , ..), merci de mettre à jour votre navigateur. </div>;
    else
      IE_warning = "";

    return (

      <header id="header">
        {IE_warning}

        <div id="header_wrapper">

          <div className="header_body">
            <a className="logo" href={"https://www.tictac.com/fr/fr/"} target={"_blank"}></a>
            <Link to={"/"} className={"logo_home"}/>

            <div id="burger" isopen={this.state.menu_open ? "1" : "0"} onClick={this.toggleMenuState}>
              <div/>
              <div/>
              <div/>
            </div>

          </div>

          <div id="menu" isopen={this.state.menu_open ? "1" : "0"}>
            <div id="menu_overlay"/>
            <nav>

              <a href={"#0"} onClick={this.toggleMenuState} id={"menu_close"}/>

              <div>
                <Link onClick={this.toggleMenuState} to="/quizz">Je participe</Link>
                <Link onClick={this.toggleMenuState} to="/#homeStrate1" smooth>Découvrir les dotations</Link>
                <Link onClick={this.toggleMenuState} to="/faq" >FAQ</Link>
              </div>
              <div>
                <Link onClick={this.toggleMenuState} to="/contact" className={"small"}>Contact</Link>
                <a onClick={this.toggleMenuState} href="/fr/fr/xp/choisistonmatch/file/Règlement.pdf" target={"_blank"} className={"small"}>Règlement du Jeu</a>
                <Link onClick={this.toggleMenuState} to="/informations-legales" className={"small"}>Informations légales</Link>
                <a onClick={this.toggleMenuState} href="https://www.tictac.com/fr/fr/politique-en-matiere-de-cookies/" target={"_blank"} className={"small"}>Politique de Cookies</a>
                <a onClick={this.toggleMenuState} href="https://www.tictac.com/fr/fr/charte-de-protection-des-donnees-personnelles/" target={"_blank"} className={"small"}>Politique de Confidentialité</a>
              </div>
            </nav>
          </div>

        </div>
      </header>
    );
  }
}

export default Header;

