import React from 'react';
import {Container} from 'react-bootstrap';

const PageHeader = (props) => {

  return (
      <div className={`strate ${props.dotation}`} id={"StrateHeader1"}>
        <div className={"header_date"}>Du 9 octobre au 3 décembre 2023</div>
        {props.children}
          <div className={"mention"}>Voir le détail des dotations et le Règlement du Jeu <a href={"/fr/fr/xp/choisistonmatch/file/Règlement.pdf"} target={"_blank"}>ici</a>.</div>
      </div>
  );
};

PageHeader.defaultProps = {
  dotation : ""
};

export default PageHeader;
